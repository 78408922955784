import React from 'react'
import Text from './Text'

const Stepper = ({ steps = 2, currentStep = 1, className = '' }) => {
  return (
    <div className={`w-full max-w-2xl mx-auto ${className}`}>
      <div className="text-base text-gray-600 mb-2">
        <Text variant="body" size="medium" tag="label">
          <span className="text-[#299D6B]">Step {currentStep}</span> of {steps}
        </Text>
      </div>
      <div className="w-full h-1 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="h-full bg-[#299D6B] rounded-full transition-all duration-300 ease-in-out"
          style={{ width: `${(currentStep / steps) * 100}%` }}
        />
      </div>
    </div>
  )
}

export default Stepper
