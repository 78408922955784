import React from 'react'
import Modal from './index'

import RecordsSuccessBackground from '../../assets/images/records/record-success-background.png'
import RecordsSuccessCardImage from '../../assets/images/records/record-success-card.png'
import Text from '../Text'
import Lottie from 'react-lottie'
import * as successCheckAnimationData from '../../assets/animations/success_check.json'
import Calender from '../Icons/Calender'

const UploadRecordSuccessModal = ({ show = false, onClose }) => {
  const successCheckAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: successCheckAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <Modal isOpen={show} onClose={onClose} fullScreen={true}>
      <div className="bg-[#EEF4FF] h-screen relative">
        <img
          src={RecordsSuccessBackground}
          alt="RecordsSuccessBackground"
          className="w-full h-full object-cover"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-full">
          <div className="relative">
            <img
              src={RecordsSuccessCardImage}
              alt="RecordsSuccessCardImage"
              className="w-[280px] object-cover"
              //   className="w-full h-full object-cover"
            />

            <div className="absolute top-[26px] w-[228px] left-0 right-0 h-full mx-auto">
              <div className="bg-[#FFF6A2] flex items-center justify-center rounded-t-xl">
                <Text
                  variant="body"
                  size="medium"
                  tag="p"
                  className="text-[#948500] py-2 text-center"
                >
                  Under Review
                </Text>
              </div>
              <div className="flex items-center justify-between p-5">
                <div>
                  <Text
                    variant="body"
                    size="medium"
                    tag="p"
                    className="font-semibold text-[#1F2933]"
                  >
                    Doctor Prescription
                  </Text>
                  <div className="flex items-center gap-2">
                    <Calender />
                    <Text
                      variant="body"
                      size="small"
                      tag="p"
                      className="text-[#1F2933]"
                    >
                      22nd Jan 2024
                    </Text>
                  </div>
                </div>
                <div>
                  <Lottie
                    options={successCheckAnimationOptions}
                    height={60}
                    width={60}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UploadRecordSuccessModal
