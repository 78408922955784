import { EVENTS } from './events'
import googleAnalyticsUtils from './google-analytics-utils'
import moEngageUtils from './mo-engage-utils'
// import webEngageUtils from './web-engage-utils'

export const initAnalytics = () => {
  // Initialize WebEngage
  // webEngageUtils.initializeWebEngage()

  // Initialize GA4
  googleAnalyticsUtils.initializeGA4()

  // Initialize MoEngage
  moEngageUtils.initializeWebEngage()
}

// const trackWebEngageEvent = (eventName, eventData) => {
//   webEngageUtils.trackEvent(eventName, {
//     appName: process.env.REACT_APP_FE_URL,
//     ...eventData,
//   })
// }

const trackGA4Event = (eventName, eventData) => {
  if (eventName === EVENTS.PAGE_VIEW && window?.location?.pathname) {
    googleAnalyticsUtils.trackPageview(
      window?.location?.pathname,
      eventData?.screenName,
    )
  } else {
    googleAnalyticsUtils.trackEvent(eventName, eventData)
  }
}

const trackMoEngageEvent = (eventName, eventData) => {
  moEngageUtils.trackEvent(eventName, eventData)
}

export const trackEvent = (eventName = '', eventData = {}) => {
  try {
    // trackWebEngageEvent(eventName, eventData)
    trackGA4Event(eventName, eventData)
    trackMoEngageEvent(eventName, eventData)
  } catch (error) {
    console.error('Error tracking event:', error)
    // Handle error as needed
  }
}

export const analytics = {
  setUserProperties: (user) => {
    moEngageUtils.setUserAttributes(user)
  },
  logout: () => {
    moEngageUtils.logout()
  },
}
