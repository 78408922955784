import Ajax from './base'

const Records = {
  updateHealthRecords: (payload) => {
    const route = '/v8/patient/updated_records'
    console.log('payload', payload)
    return Ajax.request(route, {
      method: Ajax.POST,
      priv: true,
      formData: payload,
    })
  },

  getPatientRecordWithoutVisit: (payload) => {
    const route = '/v8/patient/get_patient_record_onco_without_visit_id'
    return Ajax.request(route, {
      method: Ajax.POST,
      priv: true,
      payload,
    })
  },
}

export default Records
