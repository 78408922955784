import Ajax from './base'

const location = {
  getAddressList: (payload) => {
    const route = '/v8/tests/address_list'
    return Ajax.request(route, {
      method: Ajax.POST,
      priv: true,
      payload,
    })
  },
}

export default location
