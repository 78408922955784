import { Bounce, toast } from 'react-toastify'

export const TOAST_POSITIONS = {
  TOP_RIGHT: 'top-right',
  TOP_CENTER: 'top-center',
  TOP_LEFT: 'top-left',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_LEFT: 'bottom-left',
}

export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
}

const defaultConfig = {
  position: TOAST_POSITIONS.TOP_RIGHT,
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const showToast = (message, type = TOAST_TYPES.INFO, config = {}) => {
  const toastConfig = { ...defaultConfig, ...config }

  switch (type) {
    case TOAST_TYPES.SUCCESS:
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      break
    case TOAST_TYPES.ERROR:
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      break
    case TOAST_TYPES.WARNING:
      toast.warn(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      break
    case TOAST_TYPES.INFO:
    default:
      toast.info(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
  }
}

export const showSuccessToast = (message, config = {}) => {
  showToast(message, TOAST_TYPES.SUCCESS, config)
}

export const showErrorToast = (message, config = {}) => {
  showToast(message, TOAST_TYPES.ERROR, config)
}

export const showWarningToast = (message, config = {}) => {
  showToast(message, TOAST_TYPES.WARNING, config)
}

export const showInfoToast = (message, config = {}) => {
  showToast(message, TOAST_TYPES.INFO, config)
}
