import React from 'react'
import { createPortal } from 'react-dom'
import Text from '../Text'
import CloseIcon from '../Icons/CloseIcon'

const BottomSheet = ({ show = false, title, children, onClose }) => {
  if (!show) return null

  return createPortal(
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div className="fixed bottom-0 left-0 right-0 bg-white rounded-t-lg">
        <div className="flex justify-between items-center px-5 py-4">
          <Text variant="body" size="large" tag="p">
            {title}
          </Text>
          <button className="text-gray-500 p-2 -mr-1" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        {children}
      </div>
    </div>,
    document.body,
  )
}

export default BottomSheet
