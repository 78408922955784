import { useNavigate } from 'react-router-dom'
import { getUrlParams } from '../../../../utils/helpers/common'
import { supportProgramTypes } from '../../../../constants'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useLiverForeverJourneyHook = () => {
  const params = getUrlParams()
  const navigate = useNavigate()
  const supportProgram = supportProgramTypes[params?.support_program_type]

  const { patientDetails } = useSelector((state) => state.auth)
  const [steps, setSteps] = useState(['uploadDocuments']) // ["uploadDocuments", "address"]
  const [activeStep, setActiveStep] = useState(1)
  const [addressButtonName, setAddressButtonName] = useState('Add Address')
  const [isAddressOpen, setIsAddressOpen] = useState(false)
  const [selectedLocationCoords, setSelectedLocationCoords] = useState({
    latitude: 21.178131020124866,
    longitude: 72.83418079197986,
    enableApiCall: true,
  })
  const [selectedLocation, setSelectedLocation] = useState({
    address: '',
    buildingAddressName: '',
    cityName: '',
    pincode: null,
    streetName: '',
    latitude: 21.178131020124866,
    longitude: 72.83418079197986,
  })
  const [form, setForm] = useState({
    verifyCode: '',
    uploadDocuments: {
      //   prescription: false,
      invoice: false,
    },
  })

  const editAddress = params?.editAddress

  useEffect(() => {
    const steps = ['uploadDocuments', 'addAddress']
    setSteps(steps)
  }, [])

  useEffect(() => {
    if (
      selectedLocationCoords.latitude &&
      selectedLocationCoords.longitude &&
      activeStep === 2 &&
      selectedLocationCoords.enableApiCall
    ) {
      getAddressFromLatLong(
        selectedLocationCoords.latitude,
        selectedLocationCoords.longitude,
      )
    }
  }, [selectedLocationCoords, activeStep])

  //   useEffect(() => {
  //     if (params?.activeStep) {
  //       setActiveStep(params?.activeStep);
  //     }
  //     if (params?.editAdrress && params?.selectedAddress) {
  //       setSelectedLocation(params?.selectedAddress);
  //     }
  //   }, [params]);

  const getAddressFromLatLong = async (latitude, longitude) => {
    try {
      const uri = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&enable_address_descriptor=true`
      const response = await fetch(uri)
      if (!response.ok) {
        throw new Error('Failed to fetch data')
      }
      const data = await response.json()
      const addressBreakdown = data?.results[0]?.formatted_address?.split(',')
      const street = addressBreakdown?.[1]
      const pincode = data?.results[0]?.address_components?.find((item) =>
        item?.types?.includes('postal_code'),
      )
      const city = data?.results[0]?.address_components?.find((item) =>
        item?.types?.includes('administrative_area_level_1'),
      )
      const payload = {
        address: data?.results[0]?.formatted_address,
        buildingAddressName: addressBreakdown?.[0],
        cityName: city?.long_name,
        pincode: pincode?.long_name,
        streetName: street,
        latitude: latitude,
        longitude: longitude,
      }
      // setAddressButtonName(!!payload.pincode ? 'Proceed' : 'Add Address');
      setSelectedLocation(payload)
    } catch (error) {
      console.log('error', error)
    }
  }

  const setUploadDocStatus = (docStatus) => {
    setForm((f) => ({ ...f, uploadDocuments: docStatus }))
  }

  const onPressProceed = () => {
    setActiveStep(activeStep + 1)
  }

  const handleUpload = () => {
    // Handle file upload
    console.log('Upload clicked')
  }

  const handleBackButtonClick = () => {
    if (editAddress) {
      goToReviewScreen(selectedLocation)
    } else if (activeStep > 1) {
      setActiveStep(activeStep - 1)
    } else {
      navigate(`/care-plans/liver-forever-program`)
    }
  }

  return {
    steps,
    activeStep,
    supportProgram,
    handleUpload,
    handleBackButtonClick,
  }
}

export default useLiverForeverJourneyHook
