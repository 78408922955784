import { combineReducers } from 'redux'

import authSlice from './authSlice'
import locationSlice from './locationSlice'
import recordsSlice from './recordsSlice'
// export all reducers
export const rootReducer = combineReducers({
  auth: authSlice,
  location: locationSlice,
  records: recordsSlice,
})

// export all action
export * from './authSlice'
export * from './locationSlice'
export * from './recordsSlice'
