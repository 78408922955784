// Event const
export const GO_TO_APP = 'NAVIGATE_BACK'
export const NAVIGATE_TO_CONGRATS_SCREEN = 'NAVIGATE_TO_CONGRATS_SCREEN'
export const NAVIGATE_TO_LOGIN = 'NAVIGATE_TO_LOGIN'
export const BACK_TO_PROGRAMS = 'BACK_TO_PROGRAMS'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const LOGOUT = 'LOGOUT'
export const OPEN_EXTERNAL_APP = 'OPEN_EXTERNAL_APP'
export const TAP_KNOW_ABOUT_PROGRAM = 'TAP_KNOW_ABOUT_PROGRAM'

// Function
const postMessageToRN = (eventName, data) => {
  if (typeof window !== 'undefined' && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: eventName, data }),
    )
  }
}
export default postMessageToRN
