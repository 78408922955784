import React, { useEffect } from 'react'
import Button from '../../components/ButtonNew'
import ArrowRightIcon from '../../assets/icons/common/arrow-right.svg'
import SignupSuccessImg from '../../assets/images/signup/signup-success.png'
import moveToStore from '../../utils/helpers/deviceType'

const SignupSuccess = () => {
  useEffect(() => {
    localStorage.removeItem('auth_token')
  }, [])

  const handleDownloadClick = () => {
    // window.open('https://goodflip.com', '_blank')
    console.log('download')
    moveToStore('https://dl.mytatva.com')
  }
  return (
    // <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full overflow-hidden">
    <div className="min-w-sm max-w-lg relative mx-auto w-full overflow-hidden">
      <div className="bg-[#009856] h-screen">
        <img src={SignupSuccessImg} alt="success" />

        <div className="fixed bottom-0 left-0 right-0 p-4 min-w-sm max-w-lg mx-auto w-full bg-white">
          <Button fullWidth onClick={handleDownloadClick}>
            Download GoodFlip App <img src={ArrowRightIcon} alt="arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SignupSuccess
