import React, { useRef, useState, useEffect } from 'react'
import OtpInput from 'react-otp-input'
import Text from './Text'

const OTPInputField = ({
  label,
  className,
  onChange,
  value,
  error = false,
  helperText,
}) => {
  const [inputWidth, setInputWidth] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    const calculateWidth = () => {
      if (ref.current) {
        const containerWidth = ref.current.getBoundingClientRect().width
        // Account for 5 gaps of 8px between 6 inputs
        const totalGapWidth = 5 * 8
        const availableWidth = containerWidth - totalGapWidth
        setInputWidth(availableWidth / 6)
      }
    }

    calculateWidth()
    window.addEventListener('resize', calculateWidth)

    return () => window.removeEventListener('resize', calculateWidth)
  }, [])

  const otpInputStyle = {
    borderRadius: '8px',
    padding: '8px',
    height: '68px',
    width: inputWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '26px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#101828',
  }

  return (
    <div ref={ref} className={className} style={{ width: '100%' }}>
      {label && (
        <Text
          variant="label"
          size="medium"
          tag="p"
          className="mb-2 text-[#101828]"
        >
          {label}
        </Text>
      )}
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={6}
        placeholder="------"
        inputType="number"
        renderSeparator={() => <div className="w-2" />}
        containerStyle={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        shouldAutoFocus
        inputStyle={otpInputStyle}
        renderInput={(props) => (
          <input
            {...props}
            // type="password"
            className={`border-solid border-[1px] ${
              error && value?.length === 6
                ? `border-red-600 shadow-sm`
                : `border-[#444CE7] shadow-sm focus:ring-4 ring-[#EBF2FF]`
            }`}
          />
        )}
      />
      {helperText && (
        <Text
          variant="label"
          size="medium"
          tag="p"
          className={`mt-2 ${error ? 'text-red-600' : 'text-[#667085]'}`}
        >
          {helperText}
        </Text>
      )}
    </div>
  )
}

export default OTPInputField
