import React from 'react'

import LiverForeverProgramJourneyImg from '../../../assets/images/programs/liver-forever-program/liver-forever-program-journey.png'

const LiverForeverProgramJourney = () => {
  return (
    <img
      src={LiverForeverProgramJourneyImg}
      alt="Liver Forever Program Journey"
    />
  )
}

export default LiverForeverProgramJourney
