import React, { useEffect, useState } from 'react'

import Button from '../../components/ButtonNew'
import InputField from '../../components/InputField'

import ArrowRightIcon from '../../assets/icons/common/arrow-right.svg'
import FlowerImg from '../../assets/images/signup/flower.png'
import CallIcon from '../../assets/icons/call-icon.svg'
import EditIcon from '../../assets/icons/edit-icon.svg'
import Text from '../../components/Text'
import OTPInputField from '../../components/OTPInputField'
import UserProfileDetails from './UserProfileDetails'
import { showErrorToast } from '../../utils/toast'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/slices/index'
import { supportProgramTypes } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { getUrlParams } from '../../utils/helpers/common'
import Spinner from '../../components/Spinner'
const TERMS_URL = 'https://mytatva.in/terms-and-conditions/'
const POLICY_URL = 'https://www.tatvacare.in/mytatva/privacy-policy'

const OTP_RESEND_TIME = 30

const SignupUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = getUrlParams()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [activeScreen, setActiveScreen] = useState('phone')
  const [timer, setTimer] = useState(OTP_RESEND_TIME)
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState(false)
  const { sendOtp, verifyOtp, updateProfile } = useSelector(
    (state) => state.auth,
  )

  useEffect(() => {
    if (activeScreen === 'otp') {
      //Implementing the setInterval method
      const intervalRef = setInterval(() => {
        if (timer > 0) setTimer(timer - 1)
      }, 1000)

      //Clearing the interval
      return () => clearInterval(intervalRef)
    }
  }, [timer, activeScreen])

  useEffect(() => {
    if (verifyOtp?.data?.code === 0) {
      setOtpError(true)
    }
  }, [verifyOtp])

  const handleContinueClick = () => {
    if (phoneNumber.length === 10) {
      const payload = {
        payload: {
          contactNo: phoneNumber,
          otpType: 'LOGIN',
        },
        headers: {},
      }

      new Promise((resolve, reject) => {
        dispatch(
          actions.sendOtpRequest({
            payload,
            resolve,
            reject,
          }),
        )
      })
        .then((response) => {
          if (Number(response?.code || 0) === 1) {
            setActiveScreen('otp')
            setTimer(OTP_RESEND_TIME)
          } else {
            showErrorToast(response?.message)
          }
        })
        .catch((error) => {
          showErrorToast(error?.message)
        })
    }
  }

  const handleEditClick = () => {
    setActiveScreen('phone')
    setOtp('')
    setOtpError(false)
  }

  const handleOtpChange = (otp) => {
    setOtp(otp)
    setOtpError(false)

    if (otp.length === 6) {
      handleVerifyOtp(otp)
      // setActiveScreen('user-profile')
    }
  }

  const handleResendOtp = () => {
    setOtpError(false)
    setOtp('')
    handleContinueClick()
  }

  const handleVerifyOtp = (otp) => {
    const payload = {
      payload: {
        contactNo: phoneNumber,
        otpType: 'LOGIN',
        otp: Number(otp),
      },
      headers: {
        userFrom: 'PSP',
      },
    }

    new Promise((resolve, reject) => {
      dispatch(
        actions.verifyOtpRequest({
          payload,
          resolve,
          reject,
        }),
      )
    })
      .then(async (res) => {
        if (Number(res?.code || 0) === 1) {
          // setActiveScreen('user-profile')
          if (res?.data?.newUser) {
            localStorage.setItem('auth_token', res?.data?.token)
            setActiveScreen('user-profile')
          } else {
            navigate('/psp/signup-success')
          }
        } else {
          showErrorToast(res?.message)
        }
      })
      .catch((error) => {
        showErrorToast(error?.message)
      })
  }

  const handleFinish = (data) => {
    const payload = {
      payload: {
        register: true,

        contact_no: phoneNumber,
        name: data?.fullName,
        medical_condition_group_id:
          supportProgramTypes[params?.support_program_type]
            ?.medical_condition_group_id,
        patient_meta_info: {
          support_program_type:
            supportProgramTypes[params?.support_program_type]?.name,
          support_program_key: params?.support_program_type,
        },
        // otpType: 'LOGIN',
        // otp: Number(otp),
      },
      headers: {
        userFrom: 'PSP',
      },
    }

    new Promise((resolve, reject) => {
      dispatch(
        actions.updateProfileRequest({
          payload,
          resolve,
          reject,
        }),
      )
    })
      .then(async (res) => {
        if (Number(res?.code || 0) === 1) {
          navigate('/psp/signup-success')
        } else {
          showErrorToast(res?.message)
        }
      })
      .catch((error) => {
        showErrorToast(error?.message)
      })
  }

  if (!params?.support_program_type) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Text variant="body" size="large" tag="h2" className="text-red-600">
          Invalid program type
        </Text>
      </div>
    )
  }

  if (activeScreen === 'user-profile') {
    return (
      <UserProfileDetails
        onFinish={handleFinish}
        loading={updateProfile.loading}
      />
    )
  }

  if (activeScreen === 'otp') {
    return (
      <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full overflow-hidden">
        <img
          src={FlowerImg}
          alt="flower"
          className="w-[160px] h-[160px] absolute -z-10 top-0 right-0 -mr-[2px]"
        />
        <div className="z-10 pt-12 px-4">
          <Text
            variant="display"
            size="small"
            className="text-[#101828] mb-3"
            tag="h2"
          >
            Enter OTP
          </Text>
          <Text
            variant="body"
            size="large"
            className="text-[#344054] mb-8"
            tag="p"
          >
            An OTP is sent to the following number
          </Text>
          <div className="flex items-center gap-1 text-base font-medium text-[#101828]">
            <img src={CallIcon} alt="call-icon" className="w-6 h-6" />
            <p>+91 {phoneNumber}</p>
            <button
              className="ml-2 text-[#299D6B] flex items-center gap-1"
              onClick={handleEditClick}
            >
              Edit <img src={EditIcon} alt="edit-icon" />
            </button>
          </div>

          {/* {renderOTPInput()} */}
          <OTPInputField
            label="Secure Code"
            value={otp}
            onChange={handleOtpChange}
            className="mt-6"
            error={otpError}
            helperText={otpError ? 'Invalid OTP' : ''}
          />

          <div className="flex items-center gap-2 mt-6">
            <Text
              variant="body"
              size="medium"
              tag="p"
              className="text-[#667085]"
            >
              Didn’t receive the OTP?
            </Text>
            {sendOtp?.loading ? (
              <div className="flex items-center gap-2">
                <Text
                  variant="body"
                  size="medium"
                  tag="p"
                  className="text-[#667085]"
                >
                  Resending OTP
                </Text>
                <Spinner />
              </div>
            ) : timer > 0 ? (
              <Text
                variant="body"
                size="medium"
                tag="p"
                className="text-[#667085]"
              >
                Resend OTP in 00:{timer < 10 ? `0${timer}` : timer}
              </Text>
            ) : (
              <button onClick={handleResendOtp}>
                <Text
                  variant="body"
                  size="medium"
                  tag="p"
                  className="text-[#299D6B] font-medium"
                >
                  Resend OTP
                </Text>
              </button>
            )}
          </div>

          {verifyOtp?.loading && (
            <div className="flex items-center gap-2 mt-6">
              <Text
                variant="body"
                size="medium"
                tag="p"
                className="text-[#667085]"
              >
                Verifying OTP
              </Text>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full overflow-hidden">
      <img
        src={FlowerImg}
        alt="flower"
        className="w-[160px] h-[160px] absolute -z-10 top-0 right-0 -mr-[2px]"
      />
      <div className="z-10 pt-12 px-4">
        <Text
          variant="display"
          size="small"
          className="text-[#101828] mb-3"
          tag="h2"
        >
          Enter Mobile
          <br /> Number
        </Text>
        <Text
          variant="body"
          size="large"
          className="text-[#344054] mb-8"
          tag="p"
        >
          An OTP will be sent to the following number for verification
        </Text>

        {/* <InputNew label="Mobile Number" /> */}
        <InputField
          label="Mobile Number"
          type="tel"
          maxLength={10}
          leftIcon={<img src={CallIcon} alt="ph" className="h-6 w-6" />}
          // prefix="+91"
          placeholder="Enter your mobile number"
          value={phoneNumber}
          autoFocus
          onChange={(event) => {
            if (/^\d{0,10}$/.test(event.target.value)) {
              setPhoneNumber(event.target.value)
            }
          }}
        />

        <Text
          variant="body"
          size="small"
          className="text-[#667085] mt-4"
          tag="p"
        >
          By signing up you agree to GoodFlip’s{' '}
          <a
            href={TERMS_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold underline"
          >
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a
            href={POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold underline"
          >
            Privacy Policy
          </a>
        </Text>
      </div>

      <div className="fixed bottom-0 left-0 right-0 p-4 min-w-sm max-w-lg mx-auto w-full">
        <Button
          fullWidth
          onClick={handleContinueClick}
          loading={
            sendOtp.loading || verifyOtp.loading || updateProfile.loading
          }
          disabled={
            phoneNumber.length !== 10 ||
            sendOtp.loading ||
            verifyOtp.loading ||
            updateProfile.loading
          }
        >
          Continue <img src={ArrowRightIcon} />
        </Button>
      </div>
    </div>
  )
}

export default SignupUser
