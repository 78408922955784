//  documentType: 'ds' | 'pi' | 'dp' | 'us'
export const documentTypeTitle = (documentType) => {
  switch (documentType) {
    case 'ds':
      return {
        title: 'Discharge Summary',
        test_name: 'Discharge Summary',
      }
    case 'pi':
      return {
        title: 'Purchase Invoice',
        test_name: 'Invoice',
      }
    case 'dp':
      return {
        title: isBilypsa ? 'Doctor Prescription' : 'Prescription',
        test_name: 'Prescriptions',
      }
    case 'us':
      return {
        title: document?.title ?? 'Ultrasound Report',
        test_name: 'Progress Report',
      }
    default:
      return {
        title: '-',
        test_name: '-',
      }
  }
}
