import React, { Suspense, memo, useEffect, useRef, useState } from 'react'

import Layout from '../../../components/Layout'
import Welcome from '../../../components/Welcome'
import moveToStore from '../../../utils/helpers/deviceType'
import HeroSectionImage from '../../../assets/images/programs/prostate-care/prostate-care-hero-content.png'
import OurProgram from './OurProgram'
import ProstateCareJourney from './ProstateCareJourney'
import Highlights from './Highlights'
import Faqs from './Faqs'
import Copyrights from './Copyrights'

import './style.css'
import { getUrlParams } from '../../../utils/helpers/common'
import { trackEvent } from '../../../analytics'
import { EVENTS } from '../../../analytics/events'
import constants from '../../../constants'
import { deeplinks } from '../../../constants/common'
import RequestCallback from '../../../components/RequestCallback'
import RecoveryJourney from './RecoveryJourney'
import Button from '../../../components/ButtonNew'
import ArrowRightIcon from '../../../components/Icons/ArrowRight'

const ProstateCarePage = () => {
  const [urlParams, setUrlParams] = useState(null)
  const innerRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(document.body.scrollTop)
  const [addbottom, setBottom] = useState()
  const deeplinkToRedirect = deeplinks['prostate-care']

  useEffect(() => {
    const urlParams = getUrlParams()
    setUrlParams(urlParams)
    trackEvent(EVENTS.PAGE_VIEW, {
      screenName: 'Programs Screen',
      pageName: 'Bilypsa care',
      ...urlParams,
    })
  }, [])

  useEffect(() => {
    const div = innerRef.current
    // subscribe event
    div.addEventListener('scroll', handleOnScroll)
    return () => {
      // unsubscribe event
      div.removeEventListener('scroll', handleOnScroll)
    }
  }, [])

  const handleOnScroll = (e) => {
    // NOTE: This is for the sake of demonstration purpose only.
    // Doing this will greatly affect performance.
    setScrollTop(e.target.scrollTop)
    e.target.scrollTop >= 625 ? setBottom(true) : setBottom(false)
  }

  return (
    <Layout>
      <div
        className="container h-[100vh]"
        style={{ padding: 0, overflow: 'auto' }}
        ref={innerRef}
      >
        <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full bg-white">
          <div className="">
            <Welcome
              careProgramType={constants.CARE_PROGRAM_TYPE.BILYPSA}
              welcomeImage={HeroSectionImage}
              storeUrl={deeplinkToRedirect}
              hideDownloadBtn={
                urlParams?.source === 'mytatva' && urlParams?.platform === 'app'
              }
            />
            <OurProgram />
            <ProstateCareJourney />
            <Highlights />
            <RecoveryJourney />
            <Faqs />
            <Suspense fallback={<div>Loading...</div>}>
              <RequestCallback
                careProgramType={constants.CARE_PROGRAM_TYPE.PROSTATE}
              />
            </Suspense>
            <Copyrights />
          </div>
          <div
            className={`min-w-sm max-w-lg w-full mx-auto text-center fixed z-15 bg-white ${addbottom ? 'bottom-0' : 'bottom-[-90px]'}`}
            style={{
              padding: '15px 0',
              borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            {(urlParams?.source !== 'mytatva' ||
              urlParams?.platform !== 'app') && (
              <div className="px-4">
                <Button
                  onClick={() => moveToStore(deeplinkToRedirect)}
                  variant="contained"
                  fullWidth
                >
                  Download MyTatva App
                  <ArrowRightIcon />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default memo(ProstateCarePage)
