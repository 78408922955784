import React from 'react'

import Layout from '../../../../components/Layout'
import Text from '../../../../components/Text'

import ArrowLeftIcon from '../../../../components/Icons/ArrowLeft'
import Stepper from '../../../../components/Stepper'
import UploadDocuments from '../../../../components/care-plans/UploadDocuments'

import useLiverForeverJourneyHook from './useLiverForeverJourney.hook'

const LiverForeverJourneyPage = () => {
  const {
    steps,
    activeStep,
    supportProgram,
    handleUpload,
    handleBackButtonClick,
  } = useLiverForeverJourneyHook()

  const renderActiveStepScreen = (activeStep) => {
    switch (activeStep) {
      case 'uploadDocuments':
        return (
          <UploadDocuments
            uploadReportType={supportProgram?.upload_report_type}
          />
          // <ProstateUploadDocuments
          //   uploadDocuments={form?.uploadDocuments}
          //   setUploadDocStatus={setUploadDocStatus}
          //   onPressProceed={onPressProceed}
          // />
        )
      case 'addAddress':
        return (
          <div>Add Address</div>
          // <ProstateAddAddress
          //   selectedIndex={true}
          //   setProceedButtonTitle={buttonName => {
          //     setAddressButtonName(buttonName);
          //   }}
          //   editAddress={editAddress}
          //   isNewAddress={addressButtonName === 'Add Address'}
          //   onChangeLocation={onChangeLocation}
          //   locationCoords={selectedLocationCoords}
          //   locationAddress={selectedLocation}
          //   onBackPress={onBackPress}
          //   onProccedPress={onAddAddressProccedPress}
          // />
        )
    }
  }

  return (
    <Layout className="bg-white">
      <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full h-screen bg-[#F2F4F7]">
        <div className="flex items-center gap-2 px-4 py-2 bg-white">
          <button onClick={handleBackButtonClick}>
            <ArrowLeftIcon />
          </button>
          <Text variant="body" size="large" tag="label" className="font-bold">
            {/* {supportProgram?.name} */}
            Liver Forever program
          </Text>
        </div>
        <Stepper
          steps={2}
          currentStep={1}
          className="px-4 py-2 bg-white border-b border-gray-200"
        />

        <div
          className="overflow-y-auto"
          style={{ height: 'calc(100% - 80px)' }}
        >
          {renderActiveStepScreen(steps[activeStep - 1])}
        </div>

        {/* <div className="px-4 py-2 bg-white absolute bottom-0 left-0 right-0 border-t border-gray-200">
          <Button fullWidth onClick={handleUpload}>
            Proceed
          </Button>
        </div> */}
      </div>
    </Layout>
  )
}

export default LiverForeverJourneyPage
