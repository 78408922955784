// import React from 'react'

// import Layout from '../../../components/Layout'
// import Text from '../../../components/Text'

// import ArrowLeftIcon from '../../../components/Icons/ArrowLeft'
// import Stepper from '../../../components/Stepper'
// import UploadDocumentCard from '../../../components/care-plans/UploadDocumentCard'
// import Button from '../../../components/ButtonNew'

// import useUploadDocumentPage from './useUploadDocumentPage.hook'

// const UploadDocumentsPage = () => {
//   const { supportProgram, handleUpload, handleBackButtonClick } =
//     useUploadDocumentPage()

//   return (
//     <Layout className="bg-white">
//       <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full h-screen bg-[#F2F4F7]">
//         <div className="flex items-center gap-2 px-4 py-2 bg-white">
//           <button onClick={handleBackButtonClick}>
//             <ArrowLeftIcon />
//           </button>
//           <Text variant="body" size="large" tag="label" className="font-bold">
//             {supportProgram?.name}
//           </Text>
//         </div>
//         <Stepper
//           steps={2}
//           currentStep={1}
//           className="px-4 py-2 bg-white border-b border-gray-200"
//         />

//         <div
//           className="overflow-y-auto"
//           style={{ height: 'calc(100% - 80px)' }}
//         >
//           <div className="px-4 py-2">
//             <Text
//               variant="title"
//               size="large"
//               tag="p"
//               className="text-[#1F2933]"
//             >
//               Upload Documents
//             </Text>
//             <Text
//               variant="body"
//               size="medium"
//               tag="p"
//               className="text-[#5A6774]"
//             >
//               Upload the following document(s) to earn care coins
//             </Text>
//           </div>

//           <div className="p-4 flex flex-col gap-4">
//             <UploadDocumentCard
//               title="Doctor Prescription"
//               description="Upload your doctor's prescription which mentions the Lipaglyn medication"
//               status="Pending"
//               onClick={handleUpload}
//             />
//             <UploadDocumentCard
//               title="Doctor Prescription"
//               description="Upload your doctor's prescription which mentions the Lipaglyn medication"
//               status="Pending"
//               onClick={handleUpload}
//             />
//           </div>
//         </div>

//         <div className="px-4 py-2 bg-white absolute bottom-0 left-0 right-0 border-t border-gray-200">
//           <Button fullWidth onClick={handleUpload}>
//             Proceed
//           </Button>
//         </div>
//       </div>
//     </Layout>
//   )
// }

// export default UploadDocumentsPage

import React from 'react'

const UploadDocumentsPage = () => {
  return <div>UploadDocumentsPage</div>
}

export default UploadDocumentsPage
