import { takeLatest, put, call } from 'redux-saga/effects'
import * as actions from '../slices/index'

import Auth from '../../api/auth'

export function* sendOTPSaga(action) {
  const { payload, resolve, reject } = action.payload
  try {
    const response = yield call(Auth.sendOtp, payload)
    if (Number(response?.code) === 1) {
      yield put(actions.sendOtpSuccess(response))
      resolve(response)
    } else {
      throw new Error(response?.message || 'Something went wrong')
    }
  } catch (error) {
    yield put(actions.sendOtpError(error))
    reject(error)
  }
}

export function* verifyOTPSaga(action) {
  const { payload, resolve, reject } = action.payload
  try {
    const response = yield call(Auth.verifyOtp, payload)
    if (Number(response?.code) === 1) {
      yield put(actions.verifyOtpSuccess(response))
      resolve(response)
    } else {
      throw new Error(response?.message || 'Something went wrong')
    }
  } catch (error) {
    yield put(actions.verifyOtpError(error))
    reject(error)
  }
}

export function* updateProfileSaga(action) {
  const { payload, resolve, reject } = action.payload
  try {
    const response = yield call(Auth.updateProfile, payload)
    if (Number(response?.code) === 1) {
      yield put(actions.updateProfileSuccess(response))
      resolve(response)
    } else {
      throw new Error(response?.message || 'Something went wrong')
    }
  } catch (error) {
    yield put(actions.updateProfileError(error))
    reject(error)
  }
}

export function* getPatientDetailsSaga(action) {
  const { payload, resolve, reject } = action.payload
  try {
    const response = yield call(Auth.getPatientDetails, payload)
    if (Number(response?.code) === 1) {
      yield put(actions.getPatientDetailsSuccess(response?.data))
      resolve(response?.data)
    } else {
      throw new Error(response?.message || 'Something went wrong')
    }
  } catch (error) {
    yield put(actions.getPatientDetailsError(error))
    reject(error)
  }
}

export function* authSaga() {
  yield takeLatest(actions.sendOtpRequest, sendOTPSaga)
  yield takeLatest(actions.verifyOtpRequest, verifyOTPSaga)
  yield takeLatest(actions.updateProfileRequest, updateProfileSaga)
  yield takeLatest(actions.getPatientDetailsRequest, getPatientDetailsSaga)
}
