import { useNavigate } from 'react-router-dom'
import { supportProgramTypes } from '../../../constants'
import postMessageToRN, {
  TAP_KNOW_ABOUT_PROGRAM,
} from '../../../utils/helpers/ReactNativeEvents'
import { checkUserPlanFree } from '../../../utils/helpers/common'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/slices/index'

const useLiverForeverCarePlan = () => {
  const navigate = useNavigate()
  const supportProgram = supportProgramTypes['liver-forever-program']
  const { patientDetails } = useSelector((state) => state.auth)
  const { ftuDocumentsWithoutVisit: uploadedDocument } = useSelector(
    (state) => state.records,
  )
  const { addressList } = useSelector((state) => state.location)
  const dispatch = useDispatch()
  // const supportProgram = supportProgramTypes[params?.support_program_type]
  const isPaidUser = !checkUserPlanFree(patientDetails?.data?.patient_plans)
  console.log('isPaidUser', isPaidUser)
  console.log('addressList', addressList)

  console.log('uploadedDocument', uploadedDocument)

  useEffect(() => {
    if (!isPaidUser) {
      fetchFTUDocsWithoutVisit()
    } else if (isPaidUser) {
      console.log('LiverForeverPlanAssignedScreen')
      navigate('/care-plans/liver-forever-program/plan-assigned')
      // navigation.navigate('ProstateBottomBarStackScreen', {
      //   screen: 'ProstatePlanAssignedScreen',
      // });
    }
  }, [isPaidUser])

  useEffect(() => {
    if (!isPaidUser && uploadedDocument?.data?.length > 0) {
      const status = uploadedDocument?.data?.some(
        (record) => record?.record_meta_info?.report_status === 'Approved',
      )
      if (addressList?.data?.length > 0 || (status && !addressList?.data)) {
        console.log('addressList', addressList)
        console.log('LiverForeverPlanAssignedScreen')
        navigate('/care-plans/liver-forever-program/plan-assigned')
        // navigation.navigate('ProstateBottomBarStackScreen', {
        //   screen: 'ProstateDetailScreen',
        // })
      }
    }
  }, [uploadedDocument, addressList, isPaidUser])

  const fetchFTUDocsWithoutVisit = async () => {
    const payload = {
      patient_id: patientDetails?.data?.patient_id,
      report_type: 'prostate_cancer_report',
    }
    new Promise((resolve, reject) => {
      dispatch(
        actions.fetchLiverFTUDocumentsWithoutVisitRequest({
          payload,
          resolve,
          reject,
        }),
      )
    })
      .then((res) => {
        console.log('res', res)
        getAddressList()
      })
      .catch((error) => {
        console.log('error', error)
        // hideLoader();
      })
  }

  const getAddressList = () => {
    const payload = {}
    new Promise((resolve, reject) => {
      dispatch(
        actions.getAddressListRequest({
          payload,
          resolve,
          reject,
        }),
      )
    })
      .then((res) => {
        // setLoader(false)
      })
      .catch((error) => {
        console.log('error', error)
        // hideLoader();
      })
      .finally(() => {
        // hideLoader();
      })
  }

  const handleKnowMoreProgramClick = () => {
    postMessageToRN(TAP_KNOW_ABOUT_PROGRAM)
  }

  const handleUploadDocumentsClick = () => {
    // navigate(
    //   `/care-plans/upload-documents?support_program_type=${supportProgram.id}`,
    // )
    navigate('/care-plans/liver-forever-program/journey')
  }

  return {
    supportProgram,
    handleKnowMoreProgramClick,
    handleUploadDocumentsClick,
  }
}

export default useLiverForeverCarePlan
