import React, { useRef, useState } from 'react'
import BottomSheet from '.'
import CameraIcon from '../../assets/icons/upload-doc/camera.svg'
import GalleryIcon from '../../assets/icons/upload-doc/gallery.svg'
import FileIcon from '../../assets/icons/upload-doc/files.svg'
import ChevronRight from '../Icons/ChevronRight'
import Text from '../Text'

const CameraView = ({ onClose, onCapture, onDone }) => {
  const videoRef = useRef(null)
  const [stream, setStream] = useState(null)
  const [capturedImages, setCapturedImages] = useState([])

  React.useEffect(() => {
    startCamera()
    return () => {
      stopCamera()
    }
  }, [])

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      })
      setStream(mediaStream)
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream
      }
    } catch (err) {
      console.error('Error accessing camera:', err)
    }
  }

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop())
      setStream(null)
      if (videoRef.current) {
        videoRef.current.srcObject = null
      }
    }
  }

  const handleCapture = () => {
    const video = videoRef.current
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.getContext('2d').drawImage(video, 0, 0)
    const imageData = canvas.toDataURL('image/jpeg')
    setCapturedImages((prev) => [...prev, imageData])
  }

  const handleClose = () => {
    stopCamera()
    onClose()
  }

  const handleDone = () => {
    onDone(capturedImages)
    stopCamera()
    onClose()
  }

  return (
    <div className="fixed inset-0 bg-black z-[9999]">
      <div className="relative h-full">
        {/* Camera Preview */}
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="w-full h-full object-cover"
        />

        {/* Controls Overlay */}
        <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-50">
          <div className="flex justify-between items-center">
            <button
              onClick={handleClose}
              className="px-4 py-2 text-white bg-red-500 rounded"
            >
              Close
            </button>
            <button
              onClick={handleCapture}
              className="w-16 h-16 rounded-full bg-white border-4 border-gray-300"
            />
            <button
              onClick={handleDone}
              className="px-4 py-2 text-white bg-green-500 rounded"
            >
              Done ({capturedImages.length})
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const FileUploadBottomSheet = ({ show, onClose, onFileUpload }) => {
  const fileInputRef = useRef(null)
  const galleryInputRef = useRef(null)
  const [showCamera, setShowCamera] = useState(false)

  const handleCameraClick = () => {
    setShowCamera(true)
  }

  const handleGalleryClick = () => {
    galleryInputRef.current?.click()
  }

  const handleFileClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => {
      // Ensure mime type is set
      if (!file.type) {
        // Determine mime type based on file extension
        const extension = file.name.split('.').pop().toLowerCase()
        const mimeTypes = {
          pdf: 'application/pdf',
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
        }
        return new File([file], file.name, {
          type: mimeTypes[extension] || 'application/octet-stream',
        })
      }
      return file
    })
    onFileUpload(files)
  }

  const handleCameraDone = (images) => {
    // Handle multiple captured images
    const filePromises = images.map((imageData, index) =>
      fetch(imageData)
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], `camera_${index + 1}.jpg`, {
            type: 'image/jpeg',
          })
        }),
    )

    // Wait for all files to be processed and send them together
    Promise.all(filePromises)
      .then((files) => {
        onFileUpload(files)
      })
      .catch((error) => {
        console.error('Error processing images:', error)
      })
  }

  return (
    <>
      <BottomSheet show={show} title="Upload Document" onClose={onClose}>
        <ul>
          <li>
            <button
              className="flex items-center justify-between px-5 py-2 w-full border-b border-gray-200"
              onClick={handleCameraClick}
            >
              <div className="flex items-center gap-2">
                <img src={CameraIcon} alt="Camera" />
                <Text variant="body" size="medium" tag="p">
                  Use Camera
                </Text>
              </div>
              <ChevronRight />
            </button>
          </li>
          <li>
            <button
              className="flex items-center justify-between px-5 py-2 w-full border-b border-gray-200"
              onClick={handleGalleryClick}
            >
              <div className="flex items-center gap-2">
                <img src={GalleryIcon} alt="Gallery" />
                <Text variant="body" size="medium" tag="p">
                  Upload from gallery
                </Text>
              </div>
              <ChevronRight />
            </button>
          </li>
          <li>
            <button
              className="flex items-center justify-between px-5 py-2 w-full border-b border-gray-200"
              onClick={handleFileClick}
            >
              <div className="flex items-center gap-2">
                <img src={FileIcon} alt="File" />
                <Text variant="body" size="medium" tag="p">
                  Upload from files
                </Text>
              </div>
              <ChevronRight />
            </button>
          </li>
        </ul>

        {/* Hidden File Inputs */}
        <input
          type="file"
          ref={fileInputRef}
          accept="application/pdf"
          onChange={handleFileChange}
          className="hidden"
        />
        <input
          type="file"
          ref={galleryInputRef}
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
        />
      </BottomSheet>

      {showCamera && (
        <CameraView
          onClose={() => setShowCamera(false)}
          onCapture={() => {}}
          onDone={handleCameraDone}
        />
      )}
    </>
  )
}

export default FileUploadBottomSheet
