import React from 'react'

import RecoveryJourneyImg from '../../../assets/images/programs/liver-forever-program/liver-forever-program-recovery-journey.png'

const RecoveryJourney = () => {
  return (
    <section className="mt-[60px]">
      <img
        src={RecoveryJourneyImg}
        alt="liver-forever-program-recovery-journey-image"
      />
    </section>
  )
}

export default RecoveryJourney
