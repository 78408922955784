import React, { useState, forwardRef, memo } from 'react'
import Text from './Text'

// interface InputFieldProps
//   extends React.InputHTMLAttributes<HTMLInputElement> {
//   label?: string;
//   leftIcon?: React.ReactNode;
//   rightIcon?: React.ReactNode;
//   prefix?: string;
//   suffix?: string;
//   error?: boolean;
//   errorMessage?: string;
// }

// Define the component as a separate function that takes ref as the second argument:
const InputFieldComponent = (
  {
    label,
    value,
    onChange,
    leftIcon,
    rightIcon,
    prefix,
    suffix,
    type = 'text',
    placeholder,
    error,
    errorMessage,
    autoComplete = 'off',
    onFocus,
    onBlur,
    ...rest
  },
  ref,
) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = (event) => {
    setIsFocused(true)
    if (onFocus) {
      onFocus(event)
    }
  }

  const handleBlur = (event) => {
    setIsFocused(false)
    if (onBlur) {
      onBlur(event)
    }
  }

  const handleChange = (event) => {
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div className="w-full flex flex-col items-start gap-1">
      {label && (
        <Text
          tag="span"
          size="medium"
          variant="label"
          className="text-text-primary text-[#101828]"
        >
          {label}
        </Text>
      )}

      <div
        className={`flex items-center w-full px-4 py-2 bg-white rounded-lg border-solid
          ${
            isFocused
              ? 'border-[#444CE7] border-[1px] ring-[#EBF2FF] ring-4'
              : value
                ? 'border-[#444CE7] border-[1px]'
                : error
                  ? 'border-red-500 border-[1px]'
                  : 'border-[#444CE7] border-[1px]'
          }`}
      >
        {/* Left Icon and Prefix */}
        {leftIcon && (
          <div className="mr-2 w-6 h-6 text-gray-500 flex items-center justify-center">
            {leftIcon}
          </div>
        )}
        {prefix && (
          <div className="mr-2 text-gray-500 flex items-center justify-center">
            {prefix}
          </div>
        )}

        {/* Input Field */}
        <input
          {...rest}
          ref={ref}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type}
          placeholder={placeholder}
          className="flex-grow outline-none bg-transparent border-none placeholder-text-placeholder text-text-primary"
          style={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            textAlign: 'left',
          }}
          autoComplete={autoComplete}
        />

        {/* Suffix and Right Icon */}
        {suffix && (
          <div className="ml-2 text-gray-500 flex items-center justify-center">
            {suffix}
          </div>
        )}
        {rightIcon && (
          <div className="ml-2 w-6 h-6 text-gray-500 flex items-center justify-center">
            {rightIcon}
          </div>
        )}
      </div>

      {/* Error Message */}
      {error && errorMessage && (
        <p className="text-xs text-red-500">{errorMessage}</p>
      )}
    </div>
  )
}

// Wrap the component with forwardRef, then memo:
const InputField = memo(forwardRef(InputFieldComponent))

export default InputField
