import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  uploadRecords: {
    loading: false,
    error: null,
    data: null,
  },
  ftuDocumentsWithoutVisit: {
    loading: false,
    error: null,
    data: null,
  },
}

const recordsSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {
    updateHealthRecordRequest: (state, action) => {
      state.uploadRecords.loading = true
      state.uploadRecords.error = null
      state.uploadRecords.data = null
    },
    updateHealthRecordSuccess: (state, action) => {
      state.uploadRecords.loading = false
      state.uploadRecords.error = null
      state.uploadRecords.data = action.payload
    },
    updateHealthRecordError: (state, action) => {
      state.uploadRecords.loading = false
      state.uploadRecords.error = action.payload
      state.uploadRecords.data = null
    },

    fetchLiverFTUDocumentsWithoutVisitRequest: (state, action) => {
      state.ftuDocumentsWithoutVisit.loading = true
      state.ftuDocumentsWithoutVisit.error = null
      state.ftuDocumentsWithoutVisit.data = null
    },
    fetchLiverFTUDocumentsWithoutVisitSuccess: (state, action) => {
      state.ftuDocumentsWithoutVisit.loading = false
      state.ftuDocumentsWithoutVisit.error = null
      state.ftuDocumentsWithoutVisit.data = action.payload
    },
    fetchLiverFTUDocumentsWithoutVisitError: (state, action) => {
      state.ftuDocumentsWithoutVisit.loading = false
      state.ftuDocumentsWithoutVisit.data = null
      state.ftuDocumentsWithoutVisit.error = action.payload
    },
  },
})

export const {
  updateHealthRecordRequest,
  updateHealthRecordSuccess,
  updateHealthRecordError,

  fetchLiverFTUDocumentsWithoutVisitRequest,
  fetchLiverFTUDocumentsWithoutVisitSuccess,
  fetchLiverFTUDocumentsWithoutVisitError,
} = recordsSlice.actions

export default recordsSlice.reducer
