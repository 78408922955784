// date format will be in dayjs
export const getDateStr = (date = {}) => {
  return `${date['$y']}-${date['$M'] + 1}-${date['$D']}`
}

export const parseQueryString = (queryString) => {
  var params = {}
  var queryStringWithoutQuestionMark = queryString.substring(1) // Remove the leading '?'
  var keyValuePairs = queryStringWithoutQuestionMark.split('&')

  for (var i = 0; i < keyValuePairs.length; i++) {
    var keyValuePair = keyValuePairs[i].split('=')
    var key = decodeURIComponent(keyValuePair[0])
    var value =
      keyValuePair.length > 1 ? decodeURIComponent(keyValuePair[1]) : null

    params[key] = value
  }

  return params
}

export const getUrlParams = () => {
  if (typeof window !== 'undefined') {
    return parseQueryString(window.location.search)
  } else {
    return {}
  }
}

export const checkUserPlanFree = (patientPlans) => {
  if (!patientPlans || patientPlans?.length == 0) {
    return true
  } else if (patientPlans?.[0]?.plan_type == 'Free') {
    return true
  }
  return false
}

export const getThumbnail = async (item) => {
  const { fs } = ReactNativeBlobUtil
  const {
    dirs: { DownloadDir, DocumentDir },
  } = fs

  const directoryPath = Platform.select({
    ios: DocumentDir,
    android: DownloadDir,
  })

  const filePath = `${directoryPath}/${item?.title}`
  const exists = await fs.exists(filePath)

  if (exists) {
    await fs.unlink(filePath)
  }

  const configOptions = Platform.select({
    ios: {
      // fileCache: true,
      path: filePath,
      appendExt: 'pdf',
      notification: true,
    },
    android: {
      // fileCache: true,
      appendExt: 'pdf',
      addAndroidDownloads: {
        useDownloadManager: true,
        title: item?.title,
        mediaScannable: true,
        path: filePath,
      },
    },
  })
  try {
    const data = await ReactNativeBlobUtil.config(configOptions).fetch(
      'GET',
      item?.document_url?.[0],
    )

    const filePath = data.path()
    const fileExists = await ReactNativeBlobUtil.fs.exists(filePath)

    if (!fileExists) {
      throw new Error(`File does not exist at path: ${filePath}`)
    }

    const thumbnailUri = await generateThumbnailFromPdf({
      mime: 'application/pdf',
      path: data.path(),
      name: item?.title,
    })
    return thumbnailUri
  } catch (error) {
    console.log('[PDF FILE ERROR]: ', error)
    throw new Error()
  }
}
