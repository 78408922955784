import { takeLatest, put, call } from 'redux-saga/effects'
import * as actions from '../slices/index'

import Records from '../../api/records'

export function* updateHealthRecordSaga(action) {
  const { payload, resolve, reject } = action.payload
  try {
    const response = yield call(Records.updateHealthRecords, payload)
    if (Number(response?.code) === 1) {
      yield put(actions.updateHealthRecordSuccess(response))
      resolve(response)
    } else {
      throw new Error(response?.message || 'Something went wrong')
    }
  } catch (error) {
    yield put(actions.updateHealthRecordError(error))
    reject(error)
  }
}

const fetchLiverFTUDocumentsWithoutVisitRequest =
  function* fetchLiverFTUDocumentsWithoutVisitRequest({
    payload: { resolve, reject, payload },
  }) {
    try {
      const response = yield call(Records.getPatientRecordWithoutVisit, payload)
      console.log('response', response)
      if (Number(response?.code) === 1) {
        yield put(actions.fetchLiverFTUDocumentsWithoutVisitSuccess(response))
        resolve(response)
      } else {
        throw new Error(response?.message || 'Something went wrong')
      }
      // if (response?.code === '-1') {
      //   // throw new Error();
      //   // TODO: seesion expire
      //   throw new Error('Session Expire')
      // } else if (response?.code === '0') {
      //   throw new Error(response?.code)
      // }
      // console.log('responseeeee', response?.data)
      // if (response?.data?.length) {
      //   response?.data.map((item, index) => {
      //     response.data[index].document_url = [item?.imageURL]
      //   })
      // }
    } catch (error) {
      reject(error)
      yield put(
        actions.fetchLiverFTUDocumentsWithoutVisitError(
          error instanceof Error
            ? error?.message
            : constants.ERROR_MSG.SOMETHING_WENT_WRONG,
        ),
      )
    }
  }

export function* recordsSaga() {
  yield takeLatest(actions.updateHealthRecordRequest, updateHealthRecordSaga)
  yield takeLatest(
    actions.fetchLiverFTUDocumentsWithoutVisitRequest,
    fetchLiverFTUDocumentsWithoutVisitRequest,
  )
}
