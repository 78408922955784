import React, { useState } from 'react'
import Spinner from '../Spinner'

// variants: contained, outlined, text

const Button = ({
  children,
  variant = 'contained',
  fullWidth = false,
  onClick,
  disabled = false,
  loading = false,
}) => {
  const [isPressed, setIsPressed] = useState(false)

  const buttonBaseClass =
    'z-0 w-full h-[54px] px-4 py-2 flex items-center justify-center gap-2 rounded-lg overflow-hidden transition-all duration-300 ease-in-out text-base font-semibold'

  const buttonVariantClass = {
    contained: 'bg-[#299D6B]',
    outlined: 'bg-white border border-[#299D6B] text-[#299D6B]',
    text: 'bg-white text-[#299D6B]',
  }[variant]

  const buttonBottomBarBaseClass =
    '-z-10 w-full -mt-[12px] bg-[#2A805A] transition-all duration-300 ease-in-out transform origin-bottom overflow-hidden'

  const buttonBottomBarVariantClass = {
    contained: 'bg-[#2A805A]',
    outlined: 'bg-[#CBF0E0] border border-[#299D6B]',
    text: 'bg-transparent',
  }[variant]

  return (
    <button
      className={`flex flex-col items-end justify-end h-[66px] relative rounded-lg text-white overflow-hidden z-0 ${
        fullWidth ? 'w-full' : ''
      } ${disabled ? 'opacity-60' : ''}`}
      onMouseDown={() => {
        console.log('onMouseDown')
        setIsPressed(true)
      }}
      onMouseUp={() => {
        console.log('onMouseUp')
        setTimeout(() => {
          setIsPressed(false)
          setTimeout(() => {
            onClick && onClick()
          }, 200)
        }, 400)
      }}
      onMouseLeave={() => {
        console.log('onMouseLeave')
        setIsPressed(false)
      }}
      disabled={disabled}
      //   onClick={onClick}
    >
      <div className={`${buttonBaseClass} ${buttonVariantClass}`}>
        {loading ? (
          <Spinner color={variant === 'contained' ? '#FFF' : '#299D6B'} />
        ) : (
          children
        )}
      </div>
      <div
        className={`${buttonBottomBarBaseClass} ${buttonBottomBarVariantClass} ${
          isPressed ? 'h-[12px]' : 'h-[24px]'
        }`}
        // style={{ height: '12px' }}
      ></div>
    </button>
  )
}

export default Button
