import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from '../slices/index'
import location from '../../api/location'

export function* getAddressListSaga(action) {
  const { payload, resolve, reject } = action.payload
  try {
    const response = yield call(location.getAddressList, payload)
    if (Number(response?.code) === 1) {
      yield put(actions.getAddressListSuccess(response?.data))
      resolve(response?.data)
    } else {
      throw new Error(response?.message || 'Something went wrong')
    }
  } catch (error) {
    yield put(
      actions.getAddressListError(error?.message || 'Something went wrong'),
    )
    // reject(error)
  }
}

export function* locationSaga() {
  yield takeLatest(actions.getAddressListRequest, getAddressListSaga)
}
