import React, { useEffect } from 'react'
import { getUrlParams } from '../utils/helpers/common'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../components/Spinner'
import * as actions from '../store/slices/index'

const AuthWrapper = (Component) => {
  return (props) => {
    const params = getUrlParams()
    const { patientDetails } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const token = localStorage.getItem('auth_token') || params.auth_token

    useEffect(() => {
      if (token) {
        localStorage.setItem('auth_token', token)

        if (
          patientDetails?.loading ||
          patientDetails?.data ||
          patientDetails?.error
        ) {
          return
        }
        const payload = {}
        new Promise((resolve, reject) => {
          dispatch(
            actions.getPatientDetailsRequest({
              payload,
              resolve,
              reject,
            }),
          )
        })
      }
    }, [token, patientDetails])

    return patientDetails?.loading ? (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    ) : patientDetails?.data ? (
      <Component {...props} />
    ) : (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-base font-bold">
          Something went wrong!
        </div>
      </div>
    )
  }
}

export default AuthWrapper
