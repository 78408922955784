import SmartScaleImage from './assets/images/programs/liver-forever-program/program-offerings/devices.png'
import NutritionistImage from './assets/images/programs/liver-forever-program/program-offerings/nutritionist-consultation.png'
import PhysiotherapistImage from './assets/images/programs/liver-forever-program/program-offerings/physio-consultation.png'
import LipaglynImage from './assets/images/programs/liver-forever-program/program-offerings/lipaglyn-strips.png'

export default {
  DOCUMENT_REVIEW_STATUS: {
    UNDER_REVIEW: 'Under Review',
    REJECTED: 'Rejected',
    APPROVED: 'Approved',
  },
  CARE_PROGRAM_TYPE: {
    BILYPSA: 'bilypsa',
    AKUMENTIS: 'akumentis',
    ANAYA: 'anaya',
    PROSTATE: 'prostate',
    LIVER_FOREVER_PROGRAM: 'liver-forever-program',
  },
}

export const supportProgramTypes = {
  'liver-forever-program': {
    id: 'liver-forever-program',
    name: 'Liver Forever Program',
    medical_condition_group_id: '0c01eae6-624a-11ec-9077-ab9c0a5eb881', // TODO: change this to the actual medical condition group id
    upload_report_type: 'liver_forever_report',
    // formFields: ['name', 'email'],
    // description: "Liver Forever Program",
    // image: "https://via.placeholder.com/150",
    // link: "https://www.tatvacare.in/liver-forever-program",
    // linkText: "Learn More",
    // linkText: "Learn More",

    offerings: [
      {
        image: SmartScaleImage,
        title: 'Free Smart Scale',
        desc: 'Track weight and body metrics to stay on top of your liver health goals',
      },
      {
        image: NutritionistImage,
        title: 'Free Nutritionist Session',
        desc: 'Get personalized guidance from nutritionists to improve your liver health',
      },
      {
        image: PhysiotherapistImage,
        title: 'Free Physiotherapist session',
        desc: 'Receive expert physiotherapy sessions tailored to enhance your liver health',
      },
      {
        image: LipaglynImage,
        title: 'Free Lipaglyn Strips',
        desc: 'Get doctor-prescribed strips to support better liver and metabolic health',
      },
    ],
  },
}
