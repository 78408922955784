import React from 'react'
import Layout from '../../../components/Layout'

const LiverForeverPlanAssignedScreen = () => {
  return (
    <Layout className="bg-white">
      <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full bg-[#F2F4F7] h-screen">
        LiverForeverPlanAssignedScreen
      </div>
    </Layout>
  )
}

export default LiverForeverPlanAssignedScreen
