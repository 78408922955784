import React from 'react'
import LiverForeverCarePlanLogo from '../../../assets/images/care-plans/liver-forever-program/liver-forever-program-logo.png'
import DocumentImage from '../../../assets/images/care-plans/document.png'
import ArrowRightIcon from '../../../components/Icons/ArrowRight'
import LiverForeverProgramJourneyImg from '../../../assets/images/programs/liver-forever-program/liver-forever-program-journey.png'

import Layout from '../../../components/Layout'
import Text from '../../../components/Text'
import Button from '../../../components/ButtonNew'
import useLiverForeverCarePlan from './useLiverForeverCarePlanPage.hook'

const LiverForeverCarePlanPage = () => {
  const {
    supportProgram,
    handleKnowMoreProgramClick,
    handleUploadDocumentsClick,
  } = useLiverForeverCarePlan()

  return (
    <Layout className="bg-white">
      <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full bg-[#F2F4F7]">
        <div className="px-4 py-2">
          <img
            src={LiverForeverCarePlanLogo}
            alt="Liver Forever Care Plan Logo"
            className="h-[44px]"
          />
        </div>

        <div className="py-2 flex items-center justify-between">
          <div className="pl-4 text-[#3D4854]">
            <Text variant="title" size="large" tag="p" className="font-bold">
              Your path to
              <br />
              recovery starts here
            </Text>
            <Text
              variant="body"
              size="medium"
              tag="p"
              className="font-light mt-3"
            >
              Documents Required:
            </Text>
            <Text variant="body" size="medium" tag="p" className="font-bold">
              1. Doctor’s Prescription with Lipaglyn
            </Text>

            <button
              className="text-[#299D6B] flex items-center gap-2 mt-3"
              onClick={handleKnowMoreProgramClick}
            >
              <Text variant="body" size="large" tag="p" className="font-bold">
                Know About The Program
              </Text>
              <ArrowRightIcon color="#299D6B" />
              {/* <img
                  src={ArrowRightIcon}
                  alt="Arrow Right Icon"
                  className="text-[#299D6B]"
                  // this is not happening color change
                /> */}
            </button>
          </div>
          <div>
            <img src={DocumentImage} alt="Document Image" className="w-32" />
          </div>
        </div>

        <div>
          <Text variant="title" size="large" tag="p" className="font-bold px-4">
            Our Offerings
          </Text>

          <div className="flex items-center gap-2 py-4 overflow-scroll">
            {supportProgram?.offerings?.map((offering, index) => (
              <div
                key={offering.id}
                className={`min-w-[264px] h-[238px] bg-white rounded-lg p-4 ${
                  index === 0
                    ? 'ml-4'
                    : index === supportProgram?.offerings?.length - 1
                      ? 'mr-4'
                      : ''
                }`}
              >
                <img
                  src={offering.image}
                  alt={offering.title}
                  width={268}
                  className="w-[268px]"
                  // className="w-12 h-12"
                />
                <Text
                  variant="body"
                  size="large"
                  tag="p"
                  className="font-bold mt-3"
                >
                  {offering.title}
                </Text>
                <Text variant="body" size="medium" tag="p" className="mt-1">
                  {offering.desc}
                </Text>
              </div>
            ))}
          </div>

          <div className="px-4 pb-8">
            <img
              src={LiverForeverProgramJourneyImg}
              alt="Liver Forever Journey"
            />
          </div>
        </div>

        <div className="min-w-sm max-w-lg mx-auto fixed bottom-0 left-0 right-0 bg-white p-4 border-t border-gray-200">
          <Button
            //   variant="outlined"
            fullWidth
            onClick={handleUploadDocumentsClick}
            //   onClick={handleContinueClick}
            //   loading={
            //     sendOtp.loading || verifyOtp.loading || updateProfile.loading
            //   }
            //   disabled={
            //     phoneNumber.length !== 10 ||
            //     sendOtp.loading ||
            //     verifyOtp.loading ||
            //     updateProfile.loading
            //   }
          >
            Upload Documents <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default LiverForeverCarePlanPage
