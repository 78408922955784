import React, { useState } from 'react'
import Text from '../../components/Text'
import InputField from '../../components/InputField'
import Button from '../../components/ButtonNew'

import ArrowRightIcon from '../../assets/icons/common/arrow-right.svg'
import FlowerImg from '../../assets/images/signup/flower.png'

const UserProfileDetails = ({ onFinish, loading }) => {
  const [fullName, setFullName] = useState('')

  const handleFinish = () => {
    // navigate('/psp/signup-success')
    if (fullName) {
      onFinish({ fullName })
    }
  }

  return (
    <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full overflow-hidden">
      <img
        src={FlowerImg}
        alt="flower"
        className="w-[160px] h-[160px] absolute -z-10 top-0 right-0 -mr-[2px]"
      />
      <div className="z-10 pt-12 px-4">
        <Text
          variant="display"
          size="small"
          className="text-[#101828] mb-3"
          tag="h2"
        >
          What should we
          <br /> call you?
        </Text>
        <Text
          variant="body"
          size="large"
          className="text-[#344054] mb-8"
          tag="p"
        >
          We will refer to you by this name in the application
        </Text>
        <InputField
          label="Full Name"
          type="text"
          placeholder="Enter your full name"
          value={fullName}
          autoFocus
          onChange={(event) => {
            setFullName(event.target.value)
          }}
        />
      </div>

      <div className="fixed bottom-0 left-0 right-0 p-4 min-w-sm max-w-lg mx-auto w-full">
        <Button
          fullWidth
          onClick={handleFinish}
          disabled={!fullName || loading}
          loading={loading}
        >
          {/* Continue */}
          Let's get started <img src={ArrowRightIcon} />
        </Button>
      </div>
    </div>
  )
}

export default UserProfileDetails
