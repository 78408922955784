import { createSlice } from '@reduxjs/toolkit'

const locationInitialState = {
  permissionGiven: false,
  userLocation: null,
  addressList: {
    data: [],
    isLoading: false,
    error: null,
  },
}

export const locationSlice = createSlice({
  name: 'location',
  initialState: locationInitialState,
  reducers: {
    locationPermissionRequest: (state, action) => {},
    locationPermissionSuccess: (state, action) => {},

    // address list
    getAddressListRequest: (state, action) => {
      state.addressList.isLoading = true
      state.addressList.data = []
      state.addressList.error = null
    },
    getAddressListSuccess: (state, action) => {
      state.addressList.isLoading = false
      state.addressList.data = action.payload?.data
      state.addressList.error = null
    },
    getAddressListError: (state, action) => {
      state.addressList.isLoading = false
      state.addressList.data = []
      state.addressList.error = action.payload
    },
  },
})

export const {
  // address list
  getAddressListRequest,
  getAddressListSuccess,
  getAddressListError,
} = locationSlice.actions

export default locationSlice.reducer
