import React, { memo, useEffect, useRef, useState } from 'react'

import Layout from '../../../components/Layout'
import Welcome from '../../../components/Welcome'
import moveToStore from '../../../utils/helpers/deviceType'
import RecoveryJourney from './RecoveryJourney'
import Button from '../../../components/ButtonNew'
import OurProgram from './OurProgram'
import LiverForeverProgramJourney from './LiverForeverProgramJourney'
import Highlights from './Highlights'
import Faqs from './Faqs'
import Copyrights from './Copyrights'
import RequestCallback from '../../../components/RequestCallback'

import { useNavigate } from 'react-router-dom'

import { getUrlParams } from '../../../utils/helpers/common'
import { trackEvent } from '../../../analytics'
import { EVENTS } from '../../../analytics/events'
import constants from '../../../constants'
import { deeplinks } from '../../../constants/common'

import HeroSectionImage from '../../../assets/images/programs/liver-forever-program/liver-forever-program-hero-content.png'
import ArrowRight from '../../../assets/icons/common/arrow-right.svg'
import MetaTags from '../../../components/MetaTags/MetaTags'

const LiverForeverProgramPage = () => {
  const navigate = useNavigate()
  const [urlParams, setUrlParams] = useState(null)
  const innerRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(document.body.scrollTop)
  const [addbottom, setBottom] = useState()
  const deeplinkToRedirect = deeplinks['liver-forever-program']

  useEffect(() => {
    const urlParams = getUrlParams()
    setUrlParams(urlParams)
    trackEvent(EVENTS.PAGE_VIEW, {
      screenName: 'Programs Screen',
      pageName: 'Liver Forever Program',
      ...urlParams,
    })
  }, [])

  useEffect(() => {
    const div = innerRef.current
    // subscribe event
    div.addEventListener('scroll', handleOnScroll)
    return () => {
      // unsubscribe event
      div.removeEventListener('scroll', handleOnScroll)
    }
  }, [])

  const handleOnScroll = (e) => {
    // NOTE: This is for the sake of demonstration purpose only.
    // Doing this will greatly affect performance.
    setScrollTop(e.target.scrollTop)
    e.target.scrollTop >= 625 ? setBottom(true) : setBottom(false)
  }

  const handleJoinProgramClick = () => {
    navigate(
      `/psp/signup-user?support_program_type=${constants.CARE_PROGRAM_TYPE.LIVER_FOREVER_PROGRAM}`,
    )
  }

  return (
    <Layout>
      <MetaTags
        title="Liver Forever Program"
        description="Liver Forever Program"
        image={HeroSectionImage}
        url={window.location.href}
        type="website"
        siteName="GoodFlip"
      />

      <div
        className="container h-[100vh]"
        style={{ padding: 0, overflow: 'auto' }}
        ref={innerRef}
      >
        <div className="min-w-sm max-w-lg pb-[98px] relative mx-auto w-full bg-white">
          <div className="">
            <Welcome
              careProgramType={constants.CARE_PROGRAM_TYPE.BILYPSA}
              welcomeImage={HeroSectionImage}
              storeUrl={deeplinkToRedirect}
              hideDownloadBtn={
                urlParams?.source === 'mytatva' && urlParams?.platform === 'app'
              }
              buttonText="Join the Program"
              onButtonClick={handleJoinProgramClick}
            />
            <OurProgram />
            <LiverForeverProgramJourney />
            {/* <Highlights /> */}
            <RecoveryJourney />
            <Faqs />
            {/* <Suspense fallback={<div>Loading...</div>}>
              <RequestCallback
                careProgramType={constants.CARE_PROGRAM_TYPE.PROSTATE}
              />
            </Suspense> */}
            <Copyrights />
          </div>
          <div
            className={`min-w-sm max-w-lg w-full mx-auto text-center fixed z-15 bg-white bottom-gray-class ${addbottom ? 'bottom-0' : 'bottom-[-90px]'}`}
            // class="min-w-sm max-w-lg mx-auto fixed bottom-0 left-0 right-0 bg-white p-4"
            style={{
              padding: '15px 0',
            }}
          >
            {(urlParams?.source !== 'mytatva' ||
              urlParams?.platform !== 'app') && (
              <div className="px-4">
                <Button
                  onClick={handleJoinProgramClick}
                  variant="contained"
                  fullWidth
                >
                  Join the Program
                  <img src={ArrowRight} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default memo(LiverForeverProgramPage)
