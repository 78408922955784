import React from 'react'
import { colors } from '../../../constants/colors'

import DevicesImage from '../../../assets/images/programs/liver-forever-program/program-offerings/devices.png'
import NutritionistConsultaionImage from '../../../assets/images/programs/liver-forever-program/program-offerings/nutritionist-consultation.png'
import PhysioConsultationImageImage from '../../../assets/images/programs/liver-forever-program/program-offerings/physio-consultation.png'
import LipaglynStripsImage from '../../../assets/images/programs/liver-forever-program/program-offerings/lipaglyn-strips.png'

const OurProgram = () => {
  const ourOfferings = [
    {
      title: 'Free Smart Scale Device',
      desc: 'Track your progress with a smart body scale',
      image: DevicesImage,
    },
    {
      title: 'Free Nutritionist Consultation',
      desc: 'Expert advice to help you achieve your health goals',
      image: NutritionistConsultaionImage,
    },
    {
      title: 'Free Physio Consultation',
      desc: 'Get guidance to improve your fitness and mobility',
      image: PhysioConsultationImageImage,
    },
    {
      title: 'Free Lipaglyn Strips',
      desc: 'Support your journey with prescribed medication',
      image: LipaglynStripsImage,
    },
  ]
  return (
    <section className="px-[38px] py-[41px]">
      <h2
        className={`text-[${colors.TEXT_PRIMARY}] text-[32px] leading-[42px] font-bold tracking-[-1px]`}
      >
        Get Liver Forever
        <br />
        Program for <span className={`text-[${colors.TEXT_GREEN}]`}>free</span>
        <br />
        with every purchase
      </h2>
      <h3 className="text-[24px] leading-[42px] font-bold tracking-[-1px] py-10">
        Program Offerings
      </h3>
      <div className="flex flex-col gap-[40px]">
        {ourOfferings?.map((offering, index) => (
          <div key={`prostate-care-offerings-${index}`} className="">
            <img
              src={offering.image}
              alt={offering.title}
              className="mt-[8px] relative right-5 w-358"
            />
            <h4 className="pl-[1.5px] mt-[12px] text-[20px] text-[#1F2933] font-[600] leading-[26px]">
              {offering.title}
            </h4>
            <p className="text-[16px] text-[#5A6774] mt-3 font-[300] leading-[24px]">
              {offering.desc}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default OurProgram
