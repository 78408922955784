import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Bounce, ToastContainer } from 'react-toastify'
import { ThemeProvider, createTheme } from '@mui/material'

import '../styles/main.css'
import 'react-toastify/dist/ReactToastify.css'

import AuthWrapper from './AuthWrapper'
import Homepage from './Homepage'
import Register from './Register'
import SupportPage from './Support'

// Programs Landing Pages
import AnayaCarePage from './Programs/AnayaCare'
import BilypsaCarePage from './Programs/BilypsaCare'
import ProstateCarePage from './Programs/ProstateCare'
import LiverForeverProgramPage from './Programs/LiverForeverProgramPage'
import AkumentisCarePage from './Programs/AkumentisCare'

// Signup Pages
import SignupUser from './SignupUser/SignupUser'
import SignupSuccess from './SignupUser/SignupSuccess'

// Invalid Page
import InvalidPage from './InvalidPage'

// Care Plans Landing Pages
import LiverForeverCarePlanPage from './CarePlans/LiverForeverCarePlanPage/LiverForeverCarePlanPage'
import UploadDocumentsPage from './CarePlans/UploadDocumentsPage/UploadDocumentsPage'

import store from '../store'
import LiverForeverPlanAssignedScreen from './CarePlans/LiverForeverCarePlanPage/LiverForeverPlanAssignedScreen'
import LiverForeverJourneyPage from './CarePlans/LiverForeverCarePlanPage/LiverForeverJourneyPage/LiverForeverJourneyPage'

const AppRoutes = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#8C2FDF',
      },
      secondary: {
        main: '#54595F',
      },
    },
  })
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/register" Component={Register} />
            <Route path="/support" Component={SupportPage} />
            <Route path="/programs/anaya-care" Component={AnayaCarePage} />
            <Route path="/programs/bilypsa-care" Component={BilypsaCarePage} />
            <Route
              path="/programs/prostate-care"
              Component={ProstateCarePage}
            />
            <Route
              path="/programs/liver-forever-program"
              Component={LiverForeverProgramPage}
            />
            <Route
              path="/programs/akumentis-care"
              Component={AkumentisCarePage}
            />

            <Route path="/psp/signup-user" Component={SignupUser} />
            <Route path="/psp/signup-success" Component={SignupSuccess} />

            <Route
              path="/care-plans/liver-forever-program"
              Component={AuthWrapper(LiverForeverCarePlanPage)}
            />
            <Route
              path="/care-plans/liver-forever-program/plan-assigned"
              Component={AuthWrapper(LiverForeverPlanAssignedScreen)}
            />
            <Route
              path="/care-plans/liver-forever-program/journey"
              Component={AuthWrapper(LiverForeverJourneyPage)}
            />
            <Route
              path="/care-plans/upload-documents"
              Component={AuthWrapper(UploadDocumentsPage)}
            />

            <Route path="/" Component={InvalidPage} />
          </Routes>
        </Router>
      </ThemeProvider>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </Provider>
  )
}

export default AppRoutes
