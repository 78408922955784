import styled from 'styled-components'

const Text = styled(({ tag, children, className, style = {}, ...props }) => {
  const Tag = tag
  return (
    <Tag className={className} style={style} {...props}>
      {children}
    </Tag>
  )
})`
  font-family: ${({ variant }) =>
    variant === 'display' || variant === 'headline'
      ? 'Playfair Display, serif'
      : 'Roboto, sans-serif'};
  font-weight: ${({ variant, bold = 'false' }) =>
    bold === 'true'
      ? 700
      : variant === 'body' || variant === 'label'
        ? 400
        : 600};

  font-size: ${({ variant, size }) => {
    if (variant === 'display') {
      return size === 'large' ? '54px' : size === 'medium' ? '48px' : '40px'
    } else if (variant === 'headline') {
      return size === 'large' ? '32px' : size === 'medium' ? '28px' : '24px'
    } else if (variant === 'title') {
      return size === 'large' ? '20px' : size === 'medium' ? '18px' : '16px'
    } else if (variant === 'body' || variant === 'label') {
      return size === 'large' ? '16px' : size === 'medium' ? '14px' : '12px'
    }
  }};

  line-height: ${({ variant, size }) => {
    if (variant === 'display') {
      return size === 'large' ? '60px' : size === 'medium' ? '52px' : '44px'
    } else if (variant === 'headline') {
      return size === 'large' ? '40px' : size === 'medium' ? '36px' : '32px'
    } else if (variant === 'title') {
      return size === 'large' ? '28px' : size === 'medium' ? '26px' : '24px'
    } else if (variant === 'body' || variant === 'label') {
      return size === 'large' ? '23px' : size === 'medium' ? '21px' : '19px'
    }
  }};

  text-align: ${({ textalign }) => textalign || 'left'};

  letter-spacing: 0.25px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

Text.defaultProps = {
  tag: 'span',
  size: 'small',
  textalign: 'left',
}

export default Text
