import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Modal = ({
  isOpen,
  onClose,
  children,
  fullScreen = false,
  showCloseBtn = true,
  title,
  className = '',
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div
      className={`fixed inset-0 bg-black/50 flex items-center justify-center z-50 ${
        fullScreen ? 'p-0' : 'p-4'
      } ${className}`}
    >
      <div
        className={`bg-white relative ${
          fullScreen
            ? 'w-full h-full rounded-none'
            : 'max-w-[90%] max-h-[90vh] rounded-lg'
        } shadow-lg overflow-y-auto`}
      >
        {showCloseBtn && (
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl font-bold bg-transparent border-none cursor-pointer p-2 transition-colors"
            onClick={onClose}
            aria-label="Close modal"
          >
            ×
          </button>
        )}
        {title && <h2 className="text-xl font-semibold mb-4 pr-8">{title}</h2>}
        <div className={fullScreen ? 'p-0' : 'p-6'}>{children}</div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
}

export default Modal
