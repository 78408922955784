import Ajax from './base'

const Auth = {
  sendOtp: (data) => {
    const { payload, headers } = data || {}
    const route = '/v8/otp/send'
    return Ajax.request(route, {
      method: Ajax.POST,
      priv: false,
      isEncrypted: true,
      payload,
      headers,
    })
  },
  verifyOtp: (data) => {
    const { payload, headers } = data || {}
    const route = '/v8/otp/verify'
    return Ajax.request(route, {
      method: Ajax.POST,
      priv: false,
      isEncrypted: true,
      payload,
      headers,
    })
  },

  updateProfile: (data) => {
    const { payload, headers } = data || {}
    const route = '/v8/patient/update_profile'
    return Ajax.request(route, {
      method: Ajax.POST,
      priv: false,
      payload,
      headers,
    })
  },

  getPatientDetails: (payload) => {
    const route = '/v8/patient/get_patient_details'
    return Ajax.request(route, {
      method: Ajax.POST,
      priv: true,
      payload,
    })
  },
}

export default Auth
