import React from 'react'

const ChevronRight = ({ color = '#5A6774', width = 24, height = 25 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91016 20.42L15.4302 13.9C16.2002 13.13 16.2002 11.87 15.4302 11.1L8.91016 4.57996"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default ChevronRight
