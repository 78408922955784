import React from 'react'

const Spinner = ({ color = '#299D6B' }) => {
  return (
    <div
      className="w-6 h-6 border-4 border-gray-200 rounded-full animate-spin"
      style={{ borderTopColor: color }}
    ></div>
  )
}

export default Spinner
