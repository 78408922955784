import React from 'react'

import ExpertiseImage from '../../assets/images/expertise.png'
import TrustedImage from '../../assets/images/trusted.png'
import ArrowRight from '../../assets/icons/common/arrow-right.svg'

import moveToStore from '../../utils/helpers/deviceType'
import constants from '../../constants'
import Button from '../ButtonNew'

const Welcome = ({
  careProgramType,
  welcomeImage,
  storeUrl,
  hideDownloadBtn = false,
  buttonText = 'Download MyTatva App',
  onButtonClick = null,
}) => {
  return (
    <div className="flex flex-col relative">
      <img
        src={welcomeImage}
        width="full"
        height="full"
        className=""
        style={{ position: 'relative', zIndex: 2 }}
      />
      {!hideDownloadBtn && (
        <div
          className={`px-4 absolute   w-full z-10 text-center`}
          style={{
            top: '88%',
            // careProgramType === constants.CARE_PROGRAM_TYPE.AKUMENTIS
            //   ? '88%'
            //   : '616px',
          }}
        >
          <Button
            onClick={() =>
              onButtonClick ? onButtonClick() : moveToStore(storeUrl)
            }
            variant="contained"
            fullWidth
          >
            {buttonText}
            <img src={ArrowRight} />
          </Button>
        </div>
      )}
      {careProgramType === constants.CARE_PROGRAM_TYPE.ANAYA && (
        <div
          className="bg-[#875ECB] z-5 mt-[-28px] flex flex-col pb-[40px] pt-[100px] px-[40px] rounded-b-[30px]"
          style={{ background: 'linear-gradient(to left, #572D9E, #875ECB)' }}
        >
          <>
            <div className="flex flex-row  items-center">
              <span>
                <img src={TrustedImage} className="h-[80px] w-[80px]" />
              </span>
              <span className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
                Trusted by 900 oncologists
              </span>
            </div>
            <div className="flex flex-row  items-center mt-[50px]">
              <span>
                <img src={ExpertiseImage} className="h-[80px] w-[13.48rem]" />
              </span>
              <span className="font-[600]  pl-5 text-[16px] leading-[28px] text-white">
                Crafted with the insights and expertise of cancer survivors and
                oncologists
              </span>
            </div>
          </>
        </div>
      )}
    </div>
  )
}

export default Welcome
