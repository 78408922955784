import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sendOtp: {
    loading: false,
    error: null,
    data: null,
  },
  verifyOtp: {
    loading: false,
    error: null,
    data: null,
  },
  updateProfile: {
    loading: false,
    error: null,
    data: null,
  },
  patientDetails: {
    loading: false,
    error: null,
    data: null,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sendOtpRequest: (state, action) => {
      state.sendOtp.loading = true
      state.sendOtp.error = null
      state.sendOtp.data = null
    },
    sendOtpSuccess: (state, action) => {
      state.sendOtp.loading = false
      state.sendOtp.error = null
      state.sendOtp.data = action.payload
    },
    sendOtpError: (state, action) => {
      state.sendOtp.loading = false
      state.sendOtp.data = null
      state.sendOtp.error = action.payload
    },

    verifyOtpRequest: (state, action) => {
      state.verifyOtp.loading = true
      state.verifyOtp.error = null
      state.verifyOtp.data = null
    },
    verifyOtpSuccess: (state, action) => {
      state.verifyOtp.loading = false
      state.verifyOtp.error = null
      state.verifyOtp.data = action.payload
    },
    verifyOtpError: (state, action) => {
      state.verifyOtp.loading = false
      state.verifyOtp.data = null
      state.verifyOtp.error = action.payload
    },

    updateProfileRequest: (state, action) => {
      state.updateProfile.loading = true
      state.updateProfile.error = null
      state.updateProfile.data = null
    },
    updateProfileSuccess: (state, action) => {
      state.updateProfile.loading = false
      state.updateProfile.error = null
      state.updateProfile.data = action.payload
    },
    updateProfileError: (state, action) => {
      state.updateProfile.loading = false
      state.updateProfile.data = null
      state.updateProfile.error = action.payload
    },

    getPatientDetailsRequest: (state, action) => {
      state.patientDetails.loading = true
      state.patientDetails.error = null
      state.patientDetails.data = null
    },
    getPatientDetailsSuccess: (state, action) => {
      state.patientDetails.loading = false
      state.patientDetails.error = null
      state.patientDetails.data = action.payload
    },
    getPatientDetailsError: (state, action) => {
      state.patientDetails.loading = false
      state.patientDetails.data = null
      state.patientDetails.error = action.payload
    },
  },
})

export const {
  sendOtpRequest,
  sendOtpSuccess,
  sendOtpError,

  verifyOtpRequest,
  verifyOtpSuccess,
  verifyOtpError,

  updateProfileRequest,
  updateProfileSuccess,
  updateProfileError,

  getPatientDetailsRequest,
  getPatientDetailsSuccess,
  getPatientDetailsError,
} = authSlice.actions

export default authSlice.reducer
