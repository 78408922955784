import React from 'react'

const ArrowRightIcon = ({ color = '#FFF' }) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0914 18.5557C13.1063 18.5708 13.5644 18.6856 14.1092 18.8108C14.6541 18.936 15.1059 19.0194 15.1131 18.9961C15.1203 18.9728 15.1767 18.5946 15.2384 18.1556C15.3622 17.2741 15.6621 16.2449 15.9825 15.6013C16.9812 13.5952 18.7731 12.267 20.9464 11.9218L21.4998 11.8338V10.9477V10.0614L20.9982 9.97726C17.7119 9.42593 15.5694 6.96872 15.1686 3.29151C15.1424 3.05139 15.1111 2.855 15.0991 2.855C14.9883 2.855 13.1518 3.29759 13.117 3.33265C13.0919 3.358 13.1106 3.5831 13.1586 3.83279C13.69 6.59765 15.2117 8.84749 17.1862 9.7882L17.6599 10.0139L7.91978 10.0315L1.76074 10.0427V11.8362L7.93562 11.8474L17.6528 11.8652L17.0548 12.1682C15.5035 12.9541 14.2753 14.4958 13.552 16.5652C13.3459 17.1547 13.0349 18.4988 13.0914 18.5557Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowRightIcon
