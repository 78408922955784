import { all } from 'redux-saga/effects'
import { authSaga } from './authSaga'
import { locationSaga } from './locationSaga'
import { recordsSaga } from './recordsSaga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    locationSaga(),
    recordsSaga(),
    // Add other sagas here as needed
  ])
}
