import React, { useEffect, useRef, useState } from 'react'

import Button from '../ButtonNew'
import UploadCardTopBgImg from '../../assets/images/care-plans/upload-card-top-bg.png'
import Text from '../Text'
import { getThumbnail } from '../../utils/helpers/common'
import FileUploadBottomSheet from '../BottomSheet/FileUploadBottomSheet'
import { documentTypeTitle } from '../../utils/helpers/file'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/slices/index'
import UploadRecordSuccessModal from '../Modal/UploadRecordSuccessModal'
import moment from 'moment'
import constants from '../../constants'
import { getEncryptedText } from '../../api/base'

const DocumentView = ({
  document,
  documentType,
  documentStatus = 'pending',
  uploadReportType = 'prostate_cancer_report',
  visitId = '',
}) => {
  const [thumbnail, setThumbnail] = useState('')
  const [thumbnailLoading, setThumbnailLoading] = useState(true)
  const [showFileUploadBottomSheet, setShowFileUploadBottomSheet] =
    useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const dispatch = useDispatch()
  const { patientDetails } = useSelector((state) => state.auth)

  useEffect(() => {
    ;(async () => {
      if (!document || document?.document_url?.length <= 0) {
        return
      }
      setThumbnailLoading(true)
      await getThumbnail(document)
        .then((res) => {
          setThumbnail(res)
          setThumbnailLoading(false)
        })
        .catch((err) => {
          setThumbnail(null)
          setThumbnailLoading(false)
        })
    })()
  }, [document?.document_url])

  const title =
    documentType === 'DOCTOR_PRESCRIPTION'
      ? 'Doctor Prescription'
      : documentType === 'PURCHASE_INVOICE'
        ? 'Purchase Invoice'
        : ''

  const description =
    documentType === 'DOCTOR_PRESCRIPTION'
      ? `Upload your doctor's prescription which mentions the <b>Lipaglyn</b> medication`
      : documentType === 'PURCHASE_INVOICE'
        ? 'Upload the latest purchase invoice for <b>Lipaglyn</b> medication'
        : ''

  // const handleFileUpload = (file) => {
  //   console.log(file)
  // }

  const handleFileUpload = ({
    fileData,
    prevItem = null,
    isUploadAgain = false,
  }) => {
    console.log('fileData', fileData)
    try {
      setShowFileUploadBottomSheet(false)

      const formData = new FormData()

      let payload = {
        ...(isUploadAgain && {
          test_type_id: prevItem?.test_type_id,
          patient_records_id: prevItem?.patient_records_id,
        }),
        title: documentTypeTitle(documentType).title,
        test_name: documentTypeTitle(documentType).test_name,
        container_account: process.env.REACT_APP_AZURE_CONTAINER_ACCOUNT,
        document_data: [
          isUploadAgain
            ? document?.document_name
            : `${patientDetails?.data?.patient_id}-New-Record-${moment().unix()}.pdf`,
        ],
        container_name: 'patient-reports',
        extension_type:
          fileData?.[0]?.type !== 'application/pdf' ? 'jpg' : 'pdf',
        meta_data: {
          report_status: constants.DOCUMENT_REVIEW_STATUS.UNDER_REVIEW,
          ...(visitId.length > 0 && { visit_id: visitId }),
        },
      }

      payload['meta_data'][uploadReportType] = '1'

      // Handle file upload
      const files = Array.isArray(fileData) ? fileData : [fileData]
      console.log('Files to upload:', files)

      files.forEach((file) => {
        if (file) {
          console.log(`Processing file:`, file)
          if (file instanceof File) {
            // If it's a File object, append it directly
            formData.append('file', file)
          } else if (file.uri) {
            // If it's a React Native style object with uri
            fetch(file.uri)
              .then((res) => res.blob())
              .then((blob) => {
                const newFile = new File([blob], file.name || 'file.jpg', {
                  type: file.type || 'image/jpeg',
                })
                formData.append('file', newFile)
              })
          } else if (file instanceof Blob) {
            // If it's a Blob
            const newFile = new File([file], 'file.jpg', { type: 'image/jpeg' })
            formData.append('file', newFile)
          }
        }
      })

      // Debug FormData contents
      for (let pair of formData.entries()) {
        console.log('FormData entry:', pair[0], pair[1])
      }

      formData.append('payload', getEncryptedText(payload))

      setIsLoaderVisible(true)

      new Promise((resolve, reject) => {
        dispatch(
          actions.updateHealthRecordRequest({
            payload: formData,
            resolve,
            reject,
          }),
        )
      })
        .then((res) => {
          console.log('updateHealthRecordRequest', res)
          setIsLoaderVisible(false)
          setTimeout(() => {
            setShowSuccess(true)
          }, 500)
        })
        .catch((error) => {
          console.log('updateHealthRecordRequest error', error)
          setIsLoaderVisible(false)
        })
    } catch (error) {
      console.error('Error while uploading Health Record', error)
    }
  }

  // actual render of component
  if (!document) {
    return (
      <div>
        <div className="w-1/2 relative">
          <img src={UploadCardTopBgImg} className="-z-10" />
          {/* make it center  */}
          <div className="px-4 py-0 rounded-full border border-[#9BB1C8] absolute top-4 left-4">
            <Text
              variant="body"
              size="small"
              tag="p"
              className="text-[#9BB1C8]"
            >
              {documentStatus}
            </Text>
          </div>
        </div>
        <div className="bg-white rounded-b-lg px-4 py-5">
          <Text
            variant="body"
            size="large"
            tag="p"
            className="font-semibold text-[#1F2933] mb-2"
          >
            {title}
          </Text>
          <Text
            variant="body"
            size="medium"
            tag="p"
            className="text-[#3D4854] mb-10"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <Button
            fullWidth
            onClick={() => setShowFileUploadBottomSheet(true)}
            // onClick={handleButtonClick}
            // onClick={onClick}
          >
            Upload Document
          </Button>

          <FileUploadBottomSheet
            show={showFileUploadBottomSheet}
            onClose={() => setShowFileUploadBottomSheet(false)}
            onFileUpload={(fileData) => handleFileUpload({ fileData })}
          />

          <UploadRecordSuccessModal
            show={showSuccess}
            onClose={() => setShowSuccess(false)}
          />
        </div>
      </div>
    )
  } else if (documentStatus === 'rejected') {
    return (
      <div>
        <div className="w-1/2 relative">
          <img src={UploadCardTopBgImg} className="-z-10" />
          {/* make it center  */}
          <div className="px-4 py-0 rounded-full border border-[#9BB1C8] absolute top-4 left-4">
            <Text
              variant="body"
              size="small"
              tag="p"
              className="text-[#9BB1C8]"
            >
              {documentStatus}
            </Text>
          </div>
        </div>
        <div className="bg-white rounded-b-lg px-4 py-5">
          <Text
            variant="body"
            size="large"
            tag="p"
            className="font-semibold text-[#1F2933] mb-2"
          >
            {title}
          </Text>
          <Text
            variant="body"
            size="medium"
            tag="p"
            className="text-[#3D4854] mb-10"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <Button
            fullWidth
            // onClick={onClick}
          >
            Upload Document
          </Button>
        </div>
      </div>
    )
  }
}

export default DocumentView
