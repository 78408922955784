import React from 'react'
import Text from '../Text'
import DocumentView from './DocumentView'
import Button from '../ButtonNew'

const UploadDocuments = ({ uploadReportType }) => {
  const handleUpload = () => {
    console.log('Upload clicked')
  }
  return (
    <div className="relative">
      <div className="px-4 py-2">
        <Text variant="title" size="large" tag="p" className="text-[#1F2933]">
          Upload Documents
        </Text>
        <Text variant="body" size="medium" tag="p" className="text-[#5A6774]">
          Upload the following document(s) to earn care coins
        </Text>
      </div>

      <div className="p-4 flex flex-col gap-4">
        <DocumentView
          // title="Doctor Prescription"
          // description="Upload your doctor's prescription which mentions the Lipaglyn medication"
          // status="Pending"
          // onClick={handleUpload}
          documentType={'DOCTOR_PRESCRIPTION'}
          uploadReportType={uploadReportType}
          // documentStatus={
          //   purchaseInvoice?.record_meta_info?.report_status ===
          //   Constants.DOCUMENT_REVIEW_STATUS.REJECTED
          //     ? 'rejected'
          //     : purchaseInvoice?.record_meta_info?.report_status ===
          //         Constants.DOCUMENT_REVIEW_STATUS.UNDER_REVIEW
          //       ? 'under_review'
          //       : purchaseInvoice?.record_meta_info?.report_status ===
          //           Constants.DOCUMENT_REVIEW_STATUS.APPROVED
          //         ? 'verified'
          //         : 'pending'
          // }
          // document={purchaseInvoice}
          // afterCompletion={() => onUploadSuccess('prescription')}
          // setIsLoaderVisible={setLoader}
          // onDeletePress={() => onDeletePress('prescription')}
        />
        <DocumentView
          // title="Doctor Prescription"
          // description="Upload your doctor's prescription which mentions the Lipaglyn medication"
          // status="Pending"
          // onClick={handleUpload}
          documentType={'PURCHASE_INVOICE'}
          uploadReportType={uploadReportType}
          // documentStatus={
          //   purchaseInvoice?.record_meta_info?.report_status ===
          //   Constants.DOCUMENT_REVIEW_STATUS.REJECTED
          //     ? 'rejected'
          //     : purchaseInvoice?.record_meta_info?.report_status ===
          //         Constants.DOCUMENT_REVIEW_STATUS.UNDER_REVIEW
          //       ? 'under_review'
          //       : purchaseInvoice?.record_meta_info?.report_status ===
          //           Constants.DOCUMENT_REVIEW_STATUS.APPROVED
          //         ? 'verified'
          //         : 'pending'
          // }
          // document={purchaseInvoice}
          // afterCompletion={() => onUploadSuccess('invoice')}
          // setIsLoaderVisible={setLoader}
          // onDeletePress={() => onDeletePress('invoice')}
        />
      </div>

      <div className="px-4 py-2 bg-white fixed bottom-0 left-0 right-0 border-t border-gray-200">
        <Button fullWidth onClick={handleUpload}>
          Proceed
        </Button>
      </div>
    </div>
  )
}

export default UploadDocuments
